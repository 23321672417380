import React from "react";
import "./RoadMap.css";
import Alpaca from "../../../images/AlpacaHead.webp";
function RoadMap() {
  return (
    <div className="RoadMap-Whole-Background" id="roadmap">
      <h1 className="RoadMap-Title">Alpaca Plan</h1>
      <div className="RoadMap-background">
        <div>
          <div className="Roadmap-Plan">
            Our team is preparing to release programming courses for every age.
            What makes us unique is helping people choose their jobs. Over 88%
            of people change their jobs in the course of their lives. This is
            the result of people choosing the wrong jobs. We set out to change
            this problem. This includes Education in Metaverse. We are planning
            to host events, do charity work and create new projects in Education
            in Metaverse.
          </div>
        </div>
        <img alt="Alpaca" className="RoadMap-image" src={Alpaca} />
      </div>
    </div>
  );
}

export default RoadMap;
