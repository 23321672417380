import React, { useState } from "react";
// import { BrowserRouter as Router, Routes, Route, Link } from "react-router-dom";

import Home from "./components/Main/Home";
import AlpacaHome from "./components/Alpaca/AlpacaHome/AlpacaHome";
import StoryPage from "./components/Alpaca/Story/StoryPage";

const App = () => {
  const [pageIndex, setPageIndex] = useState(0);

  const increaseIdx = () => {
    setPageIndex((prevState) => prevState + 1);
  };

  const decreaseIdx = () => {
    setPageIndex((prevState) => prevState - 1);
  };

  return (
    <div>
     
      {pageIndex === 0 ? (
        <Home increaseIdx={increaseIdx} />
      ) : pageIndex === 1 ? (
        <AlpacaHome increaseIdx={increaseIdx} />
      ) : (
        <StoryPage decreaseIdx={decreaseIdx} />
      )}
    </div>
  );
};

export default App;
