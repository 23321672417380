import React from "react";
import alpacalogo from "../../images/logo.webp";
import "./home.css";

const Home = ({ increaseIdx }) => {
  return (
    <div className="homepage">
      <h1>Welcome To Programmer Animals Club</h1>
      <div className="clubs">
        <img src={alpacalogo} className="club-logo" alt="alpaca-head" />
        <a onClick={increaseIdx} className="enter-btn">
          Enter
        </a>
      </div>
    </div>
  );
};

export default Home;
