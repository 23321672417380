import React from "react";
import "./footer.css";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import opensea from "../../../images/opensea.svg";
import { IconContext } from "react-icons";
import alpacaLogo from "../../../images/logo.webp";

const Footer = () => {
  return (
    <div className="footer">
      <img alt="Alpaca Nft logo" src={alpacaLogo} />
      <div
        className="footer-icons"
        style={{ display: "flex", alignItems: "center" }}
      >
        <IconContext.Provider value={{ className: "f-icons" }}>
          <a target="_blank" href="https://discord.gg/4kZBwMMKvg">
            <FaDiscord />
          </a>
          <a target="_blank" href="https://twitter.com/Coding_Alpacas">
            <FaTwitter />
          </a>
        </IconContext.Provider>
        <a target="_blank" href="" style={{ width: 25, cursor: "not-allowed" }}>
          <img
            src={opensea}
            alt=""
            width={16}
            style={{ margin: 0, padding: 5 }}
          />
        </a>
      </div>
      <p className="copyright">© 2022 Programmer Animals</p>
    </div>
  );
};

export default Footer;
