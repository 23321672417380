import React from "react";
import Navbar from "../Navbar/Navbar";
import Welcome from "../Welcome/Welcome";
import Buypage from "../Buy/Buypage";
import RoadMap from "../roadmap/RoadMap";
import CardList from "../Card/CardList";
import Footer from "../Footer/Footer";
import Minting from "../Minting/Minting";

const AlpacaHome = ({ increaseIdx }) => {
  return (
    <div>
    

      <Welcome increaseIdx={increaseIdx} />
      <Minting />

      <RoadMap />
      <Buypage
        what="To grow our community"
        where="join our"
        whereBtn="Discord"
        url="https://discord.gg/4kZBwMMKvg"
        id=""
      />

      <CardList />
      <Navbar increaseIdx={increaseIdx} />
      <Footer />
    </div>
  );
};

export default AlpacaHome;
