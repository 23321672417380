import React from "react";
import "./StoryPage.css";

const StoryPage = ({ decreaseIdx }) => {
  return (
    <div className="story-section">
      <div className="StoryTextContainer">
        {" "}
        <span id="story"></span>
        <div>
          <button className="back-home" onClick={decreaseIdx}>
            Back to Home
          </button>
          <h1 className="StoryTitle" id="story">
            Story Of Alpacas
          </h1>
        </div>{" "}
        <div className="StoryDivided">
          <h1 className="StoryText">
            Ever wondered what is Area 52? what is an inside black hole like? or
            who invented bitcoin? So to get answers we have to tell the story.
            Around 13 million years ago a disturbance of space-time near mars a
            wormhole appeared. That's where our friends, programmer animals came
            from. All we know is that they look like animals but are light years
            ahead of humans in the progression.
            <br /> <br /> The story starts a long time ago in an alternative
            universe on another earth far, far away... There is no human race,
            but there are progressed animals so-called "Evolves". Some animals
            progressed faster and some slower. After some time 9 great nations
            were formed: Alpacafield, Koaland, Pythonia, Recun, Octopia, Quack,
            Empero, Skylands, and Pacifico. And just like in every other story,
            wars over power and land erupted. This war lasted over 100 years.
            Wars stopped only after someone released a bio-weapon called
            "Mad-4c" which not only infects Evolves but also infects the
            environment. The solution they came up with to save themselves from
            the virus, was to leave their universe and find a new home somewhere
            else. The only way to travel through different dimensions was
            through wormholes. But there was a slight problem. To create a
            wormhole, they needed a massive energy source, which was not
            available to humans. However, Evolves were far ahead of humans and
            they had a way to harvest the energy of dark matter, which was
            enough to create a wormhole. After harvesting dark matter and
            creating wormhole guns, they started building space stations in
            order to save enough Evolves. So in earth years, around 2005, they
            launched a spaceship with 90,000 Evolves. After traveling through
            the wormhole they landed in the "Milky way" on Mars.
            <br /> <br />
            So why are they so far ahead if they are similar to humans. So to
            put it simply, they progressed faster because of 1 major change.
            After the invention of programming in the early 1950s, they even
            started teaching it in schools in the early 1960s. This caused a big
            progression. Every nation evolved light-years faster. This was the
            one main change in progression. I mean this when I say that 1 Evolve
            year equals 100 human years.
          </h1>
          \
        </div>
      </div>
    </div>
  );
};

export default StoryPage;
