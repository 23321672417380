import React from "react";
import "./card.css";

const Card = ({ name, role, img }) => {
  return (
    <div className="image-area">
      <div className="img-wrapper">
        <img src={img} alt="Alpaca NFT" />
        <h2>
          {name} {role}
        </h2>
        <ul>
          <li>
            <a
              href="https://discord.gg/4kZBwMMKvg"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-discord"></i>
            </a>
          </li>

          <li>
            <a
              href="https://twitter.com/Coding_Alpacas"
              target="_blank"
              rel="noreferrer"
            >
              <i className="fab fa-twitter"></i>
            </a>
          </li>
        </ul>
      </div>
    </div>
  );
};

export default Card;
