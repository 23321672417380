import React from "react";
import { CgScrollV } from "react-icons/cg";
import { IconContext } from "react-icons";
import gif from '../../../images/gif.gif'
import "./welcome.css";
const Welcome = ({ increaseIdx }) => {
  return (
    <div className="welcome-section" id="home">
      <div className="welcome-text">
        <div className="welcome-left">
          <p>
            Ever wondered what is Area 52 ? <br />
            what is an inside black hole like?
            <br /> or who invented bitcoin?
            <br /> So to get answers we have to tell
            <br /> the story
            <a onClick={increaseIdx} href="#story">
              <span> See more...</span>
            </a>
          </p>
          <a
            href="https://discord.gg/4kZBwMMKvg"
            target="_blank"
            rel="noreferrer"
          >
            <button>Join Our Discord</button>
          </a>

          <IconContext.Provider value={{ className: "wc-icons", size: "2em" }}>
            <CgScrollV />
          </IconContext.Provider>
        </div>
        <span id="buy"></span>
        <div className="DataKaiRaa">
          <img
            alt="Programmer Alpaca NFT"
            src={gif}
            height={450}
          />
        </div>
      </div>
    </div>
  );
};

export default Welcome;
