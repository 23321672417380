import React, { useRef, useState, useEffect } from "react";

import "./navbar.css";
import { FaDiscord, FaTwitter } from "react-icons/fa";
import opensea from "../../../images/opensea.svg";
import { IconContext } from "react-icons";
import logo from "../../../images/logo.webp";
const Navbar = ({ increaseIdx }) => {
  const [show, setShow] = useState(true);

  const [lastScrollY, setLastScrollY] = useState(0);

  const controlNavbar = () => {
    if (window.scrollY < lastScrollY) {
      setShow(true);
    } else {
      setShow(false);
    }

    setLastScrollY(window.scrollY);
  };

  useEffect(() => {
    window.addEventListener("scroll", controlNavbar);
    return () => {
      window.removeEventListener("scroll", controlNavbar);
    };
  }, [lastScrollY]);
  const elementRef = useRef(null);
  console.log(elementRef.current?.clientHeight);

  return (
    <div ref={elementRef} className={show ? "sticky" : "hidden"}>
      <div className="navbar">
        <img className="logo" src={logo} alt="Alpaca NFT" />
        <h1 className="title">Programmer Alpacas</h1>
        <div className="nav">
          <a className="navbar-item2" href="#home">
            Home
          </a>
          <a className="navbar-item" href="#buy">
            Mint
          </a>
          <a className="navbar-item" href="#roadmap">
            Plan
          </a>

          <a className="navbar-item2" onClick={increaseIdx} href="#story">
            Story
          </a>
          <h1
            style={{ cursor: "not-allowed", fontSize: 16 }}
            className="navbar-item2"
            href=""
          >
            P2E
          </h1>

          <a className="navbar-item" href="#creators">
            Creators
          </a>
          <div
            style={{ display: "flex", alignItems: "center", marginRight: 25 }}
          >
            <IconContext.Provider value={{ className: "icons" }}>
              <a
                href="https://discord.gg/4kZBwMMKvg"
                target="_blank"
                rel="noreferrer"
              >
                <FaDiscord />
              </a>
              <a
                href="https://twitter.com/Coding_Alpacas"
                target="_blank"
                rel="noreferrer"
              >
                <FaTwitter />
              </a>
            </IconContext.Provider>
            <a
              href="/"
              target="_blank"
              rel="noreferrer"
              style={{
                width: 25,

                cursor: "not-allowed",
              }}
            >
              <img
                src={opensea}
                alt=""
                width={16}
                style={{ margin: 0, padding: 10 }}
              />
            </a>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Navbar;
