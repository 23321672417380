import React from "react";
import "./buypage.css";

const Buypage = ({ what, where, whereBtn, id, url }) => {
  return (
    <div className="buy-section" id={`${id}`}>
      <h2>
        <span className="IDunnoHowThisWorks">{what}</span> {where}
      </h2>
      <a href={`${url}`} target="_blank">
        <button>{whereBtn}</button>
      </a>
    </div>
  );
};

export default Buypage;
