import React from "react";
import Card from "./Card";

import team from "../../../images/team.webp";
const CardList = () => {
  return (
    <div className="creators-title" id="creators">
      <h1>CREATORS</h1>
      <div className="creators-text">
        <h2>
          We are group mates set out to change world of programming for
          begginers forever. <br />
          This days we have so many programming languages and graphical
          applications a newbie doesn't know what to pursuit. <br />
          We will solve this problem.
        </h2>
      </div>

      <Card name="Our team is our" role="Community" img={team} />
      <div />
    </div>
  );
};

export default CardList;
