import React, { useEffect, useState } from "react";
import "./Minting.css";
import { ethers } from "ethers";
import AlpacaNFT from "../../../utils/AlpacaNFT.json";
import AlpacaHead from '../../../images/AlpacaHead2.png'
import red from '../../../images/red.png'
import ThreeAlpacas from '../../../images/ThreeAlpacas.jpg'
import ThreeAlpacasPhone from  '../../../images/ThreeAlpacas3.jpg'
// const TOTAL_MINT_COUNT = 10000;

const Minting = () => {
  const [currentAccount, setCurrentAccount] = useState("");

  const checkIfWalletIsConnected = async () => {
    /*
     * First make sure we have access to window.ethereum
     */
    const { ethereum } = window;

    if (!ethereum) {
      console.log("Make sure you have metamask!");
      return;
    } else {
      console.log("We have the ethereum object", ethereum);
    }

    const accounts = await ethereum.request({ method: "eth_accounts" });

    /*
     * User can have multiple authorized accounts, we grab the first one if its there!
     */
    if (accounts.length !== 0) {
      const account = accounts[0];
      console.log("Found an authorized account:", account);
      setCurrentAccount(account);
    } else {
      console.log("No authorized account found");
    }
  };
  const connectWallet = async () => {
    try {
      const { ethereum } = window;

      if (!ethereum) {
        alert("Get MetaMask!");
        return;
      }

      /*
       * Fancy method to request access to account.
       */
      const accounts = await ethereum.request({
        method: "eth_requestAccounts",
      });

      /*
       * Boom! This should print out public address once we authorize Metamask.
       */
      console.log("Connected", accounts[0]);
      setCurrentAccount(accounts[0]);
    } catch (error) {
      console.log(error);
    }
  };
  const askContractToMintNft = async () => {
    const CONTRACT_ADDRESS = "0x7F7bf11Dc35D2bbCd838D64b8410792fe4e3cB25";

    try {
      const { ethereum } = window;

      if (ethereum) {
        const provider = new ethers.providers.Web3Provider(ethereum);
        const signer = provider.getSigner();
        const connectedContract = new ethers.Contract(
          CONTRACT_ADDRESS,
          AlpacaNFT.abi,
          signer
        );

        console.log("Going to pop wallet now to pay gas...");
        let nftTxn = await connectedContract.mintAlpaca();

        console.log("Mining...please wait.");
        await nftTxn.wait();

        console.log(
          `Mined, see transaction: https://rinkeby.etherscan.io/tx/${nftTxn.hash}`
        );
      } else {
        console.log("Ethereum object doesn't exist!");
      }
    } catch (error) {
      console.log(error);
    }
  };
  // Render Methods
  const renderNotConnectedContainer = () => (
    <button
      onClick={connectWallet}
      className="cta-button connect-wallet-button"

    >
      Free NFT Mint
    </button>
  );

  useEffect(() => {
    checkIfWalletIsConnected();
  }, []);

  return (
    <div className="minting">
   <img alt="alpacas" className="AlpacaHead" src={ThreeAlpacas}></img>
   <img alt="alpacas" className="AlpacaHead2" src={ThreeAlpacasPhone}></img>
      <div className="m-container">
      <div className="freeNFT"> Get Your Free Programmer Alpaca Now</div>
      <div className="freeNFT2"> Get Your Alpaca</div>
        <div className="m-header-container">
      
          <div className="spaceAround">

          {currentAccount === "" ? (
            renderNotConnectedContainer()
          ) : (
            <button
              onClick={askContractToMintNft}
              className="cta-button connect-wallet-button"
            >
        
            </button>
          )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default Minting;
